export const Routes = {
  homepage: '/',
  playGame: '/casino/play/:gameId/:providerId/:gameIdentifier/:subCategoryName/:gameName/:catgoryId',
  notFound: '/casino/404',
  forbidden: '/casino/403',
  casino: '/casino',
  favourites: '/casino/favourites',
  setting: '/setting',
  affiliate: '/affiliate',
  mybet: '/casino/mybet',
  transaction: '/transaction',
  viewAllGame: '/casino/viewAllGame/:category/:name',
  providerGames: '/casino/providerAllGame/:providerId',
  provider: '/casino/provider',
  allprovider: '/casino/allprovider',
  comingsoon: '/casino/comingsoon',
  bonusTickets: '/casino/bonus-tickets',
  event: '/casino/event',
  myvoucher: '/casino/my-voucher',

  // other
  cms: '/categories/:slug/:cmsPageId',
  kyc: '/kyc',
  userLevel: '/user-level',
  achievements: '/achievements',
  promotions: '/promotions',
  winnings: '/winnings',
  bonusDashboard: '/bonus-dashboard',
  promotionPage: '/promotion-info',

  // sportsbook
  sportsbook: '/sportsbook',
  sportsDetail: '/sportsbook/:matchId',
  sportsTransaction: '/sportsbook/transaction',
  myBets: '/sportsbook/mybets',
  results: '/sportsbook/results',
  favorites: '/sportsbook/favorites',
  sportsbonusDetails: '/sportsbook/bonus-details',
  sportsaddOnConfig: '/sportsbook/deposit-rolling',
  sportspromotionsRollings: '/sportsbook/promotions-rolling',
  betbySportsbook: '/betby-sports/*',
  betbySportsbookRoute: '/betby-sports'

}
